<template>
  <div class="border bg-white custom_dialog rounded-xl" style="max-width: 720px;max-height: 90%; overflow:auto;" >
    <div class="container">
      <div>
        <div class="card ">
          <div class="mb-2 rounded px-5 py-2 bg-primary text-white heading-4 font-semibold flex items-center justify-between">
            <div>
              <p class="">Shift Filter</p>
            </div>
            <div class="">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="closeDialog(null)"/>
              <Button class="ml-2" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Apply'" @buttonAction="emitSelectedDatesHandler"/>
            </div>  
          </div>
          <div class="p-2 flex items-center justify-between ">
            <p class="text-text2 heading-6 pl-2" v-if="(startDate !== '' && startDate !== 'Invalid date') && (endDate !== '' && endDate !== 'Invalid date')">*From {{ startDate | dateStringFilter}} to {{ endDate | dateStringFilter}}</p>
            <div class="heading-5 pr-2 text-primary cursor-pointer" @click="resetFilter()">Reset Filter</div>
          </div>
          <div class="p-2 flex flex-wrap" v-if="isShowMore">
            <div class="m-1" v-for="(status, index) in buttonFiltersArray" :key="index">
              <StatusBtn class=" whitespace-nowrap" @buttonAction="getDateTimeForFilterHandler(index)" :selected="status.selected" :btnText="status.title" :lastInlist="buttonFiltersArray.length === index + 1"/>     
            </div>
          </div>
          <div class="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 lg:grid-cols-2 justify-items-center pb-4">
            <div class="p-2 pr-0 box">
              <p class="text-primary2 font-semibold mb-2 heading-5">Start Date:</p>
              <DateTimePickerCustom
                v-model="startDate"
                :title="''"
                :showOnlyDate="true"
                :hideGoToday="true"
                :hideBtn="true"
                :roundedCard="true"
                >
            </DateTimePickerCustom>
            </div>
            <div class="px-2 py-2 box">
                <p class="text-primary2 font-semibold mb-2 heading-5">End Date:</p>
                 <DateTimePickerCustom
                  v-model="endDate"
                  :title="''"
                  :showOnlyDate="true"
                  :hideGoToday="true"
                  :hideBtn="true"
                  :roundedCard="true"
                  >
              </DateTimePickerCustom>
            </div>
          </div>
          </div>
      </div>
    </div>
</div>
</template>
<script>
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import Button from '@/View/components/globalButton.vue'
var moment = require('moment')
import DateTimePickerCustom from '@/View/components/datepicker/datepicker.vue'
export default {
  components: {
    Button,
    StatusBtn,
    DateTimePickerCustom,
  },
  data () {
    return {
      startDate: '',
      selectedValue: '',
      endDate: '',
      buttonFiltersArray: [
        {
          title: 'Today',
          selected: false,
          value: 'today'
        },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        {
          title: 'This Week',
          selected: false,
          value: 'thisWeek'
        },
        {
          title: 'This Month',
          selected: false,
          value: 'thisMonth'
        },
        {
          title: 'Pre 4 Days',
          selected: false,
          value: '4Days'
        },
        {
          title: 'Pre 7 Days',
          selected: false,
          value: 'pre7Days'
        },
        {
          title: 'Pre 15 Days',
          selected: false,
          value: '15Days'
        },
        {
          title: 'Pre 30 Days',
          selected: false,
          value: 'last30Days'
        },
        {
          title: 'Pre 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Pre 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Pre 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Pre 180 Days',
          selected: false,
          value: 'last180Days'
        }
      ],
    }
  },
  props: ['appliedFilters', 'selectedValue1', 'isShowMore'],
  watch: {
    startDate: {
      handler () {
        console.log('startDate WATCH', this.startDate)
      },
      deep: true
    },
    endDate: {
      handler () {
        console.log('endDate WATCH', this.endDate)
      },
      deep: true
    }
  },
  beforeMount () {
    this.startDate =  moment(this.appliedFilters.startDate).format('YYYY-MM-DD').toString()
    this.endDate = moment(this.appliedFilters.endDate).format('YYYY-MM-DD').toString()
    console.log('this.startDate', this.startDate)
    console.log('this.endDate', this.endDate)
  },
  mounted () {
    // this.startDate = this.appliedFilters.startDate !== '' ? this.$options.filters.formatForDatePicker(this.appliedFilters.startDate) : new Date().toISOString().substr(0, 10)
    // this.endDate = this.appliedFilters.endDate !== '' ? this.$options.filters.formatForDatePicker(this.appliedFilters.endDate) : new Date().toISOString().substr(0, 10)
    console.log('this.appliedFilters', this.appliedFilters)
    this.startDate =  moment(this.appliedFilters.startDate).format('YYYY-MM-DD').toString()
    this.endDate = moment(this.appliedFilters.endDate).format('YYYY-MM-DD').toString()
    this.selectedValue = this.appliedFilters.selectedValue
    for (let index = 0; index < this.buttonFiltersArray.length; index++) {
      console.log('this.selectedValue', this.selectedValue)
      console.log('this.buttonFiltersArray[index].value', this.buttonFiltersArray[index].value)
      if (this.buttonFiltersArray[index].value === this.appliedFilters.selectedValue) {
        this.buttonFiltersArray[index].selected = true
      } else {
        this.buttonFiltersArray[index].selected = false
      }
    }
    console.log('this.startDate', this.startDate)
    console.log('this.endDate', this.endDate)
  },
  methods: {
    resetFilter () {
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (this.buttonFiltersArray[i].value === 'thisWeek') {
          this.buttonFiltersArray[i].selected = true
        } else {
          this.buttonFiltersArray[i].selected = false
        }
      }
      this.selectedValue = 'thisWeek'
      this.buttonFiltersArray[0].selected = true
      getDayAccordingToLimits('thisWeek').then(val => {
        this.startDate = moment(val.startDate).format('YYYY-MM-DD').toString()
        this.endDate = moment(val.endDate).format('YYYY-MM-DD').toString()
      })
    },
    emitSelectedDatesHandler () {
      let obj = {
        startDate: this.startDate,
        endDate: this.endDate,
        selectedValue: this.selectedValue
      }
      this.closeDialog(obj)
    },
    closeDialog (data) {
      this.$emit('closeSelectedDialog', data)
    },
    getDateTimeForFilterHandler (index) {
      this.selectedValue = this.buttonFiltersArray[index].value
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
        this.startDate = moment(val.startDate).format('YYYY-MM-DD').toString()
        this.endDate = moment(val.endDate).format('YYYY-MM-DD').toString()
      })
    },
  }
}
</script>
<style scoped>
.popup_overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.445);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
</style>
