<template>
  <div class="h-full cust_card p-2 mt-2" style="overflow: visible !important;">
    <div class="heading-3 text-text1">● Activty:</div>
    <div class="bottom_space rounded py-2 h-full pt-2 bg-white">
      <div class="">
        <div class="flex overflow-auto mb-2 items-center">
          <div class="pr-2  text-gray4 heading-5 ">Entity:</div>
          <div class="" v-for="(status, index) in entStatusList" :key="index"
          :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index)" :selected="status.selected" :btnText="status.entityName" :lastInlist="entStatusList.length === index + 1"/>     
          </div>
        </div>
        <div :class="mobileView ? '' : 'flex items-center justify-between py-2 '" class="">
          <div class="flex flex-wrap items-end gap-4">
            <div class="w-72 ">
              <p class="label_css">Search Activity</p>
              <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Activity...`"/>
            </div>
            <div class="w-96 ">
              <Dropdown
                :inputext="accountingCodeName"
                :inputId="'orgs'"
                :lableText="'Organization'"
                :placholderText="`Select Organization`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :showPlus="false"
                :keyName="'fullName'"
                :showClearIcon="true"
                :listId="'organizationDetailId'"
                :items="accountingCodeList"
                @inputChangeAction="callsearchCust"
                @selectNewForVal="selectCatValue"
                 />
            </div>
            <div class="w-96 " v-if="isShowUser">
              <Dropdown
                :inputext="UserName"
                :inputId="'users'"
                :lableText="'User'"
                :placholderText="`Select User`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :showPlus="false"
                :showClearIcon="true"
                :keyName="'fullName'"
                :listId="'organizationDetailId'"
                :items="userList"
                @selectNewForVal="selectUserValue"
                 />
            </div>
            <div class="flex items-center">
              <div class="pr-2  text-gray4 heading-5 ">Date Filter:</div>
              <StatusBtn class=" whitespace-nowrap" @buttonAction="selectDate1('all')" :selected="isDateSelectAll" :btnText="'All'" />     
              <StatusBtn class=" whitespace-nowrap" @buttonAction="selectDate1('custom')" :selected="!isDateSelectAll" :btnText="'Custom'" />     
            </div>
          </div>
          <div>
            <div @click.stop="expandCollaps()" class="heading-5 font-semibold text-primary px-2 cursor-pointer">{{ isExapndAll ? 'Expand all' : 'Collapse all'}}</div>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="activitiListing.length > 0">
            <div v-for="(data, index) in activitiListing" :key="index"  class="cust_card m-2 mx-1  heading-3 border-b border-gray  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
              <div class="" v-if="!mobileView">
                <div class="flex justify-between" @click="openDetail(index)">
                  <div class="flex" >
                    <div>
                      <span v-html="getIcons(data.entitySlug)"></span>
                    </div>
                    <div class="pl-3">
                      <div class="text-text2 heading-5 font-semibold">{{data.activityTitle}}</div>
                      <div class="text-gray4 heading-6"> {{data.firstName}} {{data.lastName}} - {{data.orgName}} ({{data.orgCode}})</div>
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.shortActivityDesc !== '' && !data.seeFullDesc">
                        <span>{{data.shortActivityDesc}}</span>
                      </div>
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== '' && data.seeFullDesc">
                        <span>{{data.activityDesc}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="heading-5 flex" >
                    <span class="text-gray4 heading-5 hover:text-primary" @click="redirectDetail(data)">{{data.addedDate | dateManyDayAgo}}</span>
                    <span v-if="data.activityDesc.length > 60">
                      <div v-if="data.seeFullDesc"><i class="fa-solid fa-chevron-up pl-2 text-primary"></i></div>
                      <span v-if="!data.seeFullDesc"><i class="fa-solid fa-chevron-down pl-2 text-primary"></i></span>
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="mobileView" @click="openDetail(index)">
                <span class="text-gray4 heading-5 text-right flex justify-end hover:text-primary" @click="redirectDetail(data)">{{data.addedDate | dateManyDayAgo}}
                  <span v-if="data.activityDesc.length > 60">
                      <div v-if="data.seeFullDesc"><i class="fa-solid fa-chevron-up pl-2 text-primary"></i></div>
                      <span v-if="!data.seeFullDesc"><i class="fa-solid fa-chevron-down pl-2 text-primary"></i></span>
                    </span>
                </span>
                <div class=" relative items-center flex">
                  <div class="flex items-center">
                    <span v-html="getIcons(data.entitySlug)"></span>
                    <div class="text-gray4 heading-6 pl-2 whitespace-pre-line" >{{data.firstName}} {{data.lastName}} - {{data.orgName}} ({{data.orgCode}})</div>
                  </div>
                </div>
                <div class="text-text2 heading-5 pl-7">{{data.activityTitle}}</div>
                <div class="text-gray3 heading-5 whitespace-pre-line pl-7" v-if="data.shortActivityDesc !== '' && !data.seeFullDesc">
                  <span>{{data.shortActivityDesc}}</span>
                </div>
                <div class="text-gray3 heading-5 whitespace-pre-line pl-7" v-if="data.activityDesc !== '' && data.seeFullDesc">
                  <span>{{data.activityDesc}}</span>
                </div>
              </div>
            </div>
          <div>
          <Pagination
            :totalDataCount="totalQuoteCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getQuoteDataFromPagination"/>
        </div>
        </div>
        <div class="layout my-2" v-if="activitiListing.length === 0">
          <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
              <p class=" font-medium heading-4">No record found.</p>
            </div>
          </div>
        </div>
      </div>
     </div>
     <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="btnDatePicker" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker" :isShowMore="true"/>
      </div>
    </div>
   </div>
</template>
<script>
import CustomDateSelector from '@/View/components/customDateSelection'
import Dropdown from '@/View/components/dropdown.vue'
// import SimpleDropdown from '@/View/components/simpleDropdown.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
// import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
export default {
  name: "activity",
  components: {
    Dropdown,
    CustomDateSelector,
    // SimpleDropdown,
    StatusBtn,
    Pagination,
    SearchTextInput,
    // Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      orgID: 0,
      btnDatePicker: {
        startDate: '',
        endDate: '',
        exportData: false,
        selectedEmpId: '',
        selectedValue: '',
      },
      showCustomDatepicker: false,
      isDateSelectAll: true,
      isExapndAll: true,
      isShowUser: false,
      accountingCodeName: '',
      UserName: '',
      accountingCodeList: [],
      userList: [],
      searchValCode: '',
      mobileView: false,
      titleObj: [
        {title: 'Quotation #', icon: '', sortName: 'quotationNumber'},
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Grand Total', icon: '', sortName: 'grandTotal'},
        {title: 'Expiry Date', icon: 'DESC', sortName: 'expiryDate'},
        {title: 'Status', icon: '', sortName: 'entityName'}
      ],
      entStatusList: [],
      userId: [],
      activitiListing: [],
      searchForQuotation: '',
      getQuotationDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalQuoteCount: 0,
      items: [20, 25, 30, 50, 100],
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        sortBy: 'addedDate',
        entStatusList: [],
        userId: '',
        customerId: '',
        from: '',
        to: '',
        organizationId: 0,
      },
    };
  },
  watch: {
    activitiListing: {
      handler () {
        console.log('activitiListing', this.activitiListing)
        let totalCount = 0
        let totalTrue = 0
        this.activitiListing.forEach(element => {
          if (element.activityDesc.length > 60) {
            totalCount += 1
          }
        });
        console.log('count', totalCount)
        this.activitiListing.forEach(element => {
          if (element.activityDesc.length > 60) {
            if (element.seeFullDesc) {
              totalTrue += 1
            } else {
              totalTrue -1
            }
          }
        });
        if (totalTrue === totalCount) {
          this.isExapndAll = false
        } 
        console.log('totalTrue', totalTrue)
      },
      deep: true
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    let query = this.$route.query.fromorgid
    this.orgID = query !== null ? query : 0
    let Today = new Date()
    this.btnDatePicker.startDate = new Date((Today).setHours(0, 0, 0, 0)).toUTCString()
    this.btnDatePicker.endDate = new Date((Today).setHours(24, 0, 0, 0)).toUTCString()
    this.resizeWindowHandler()
    this.getQuotationDebounce = this.debounce(function () {
      this.resetPaginationValues = true
      // this.getActivityList()
    }, 500)
    this.getEnitityList()
    this.getOrgListing(this.orgID)
  },
  beforeDestroy() {
  },
  methods: {
    customDateListener (data) {
    this.showCustomDatepicker = false
    if (data !== null) {
      this.isDateSelectAll = false
      this.btnDatePicker.startDate = data.startDate
      this.btnDatePicker.endDate = data.endDate
      this.filterObject.from = data.startDate
      this.filterObject.to = data.endDate
      this.btnDatePicker.selectedValue = data.selectedValue
      setTimeout(() => {
        this.resetPaginationValues = true
      }, 100);
    }
  },
    selectDate1 (data) {
      if (data === 'all') {
        this.isDateSelectAll = true
        this.filterObject.to = ''
        this.filterObject.from = ''
        this.btnDatePicker.selectedValue = ''
        this.btnDatePicker.startDate = new Date((new Date()).setHours(0, 0, 0, 0)).toUTCString()
        this.btnDatePicker.endDate = new Date((new Date()).setHours(24, 0, 0, 0)).toUTCString()
        setTimeout(() => {
          this.resetPaginationValues = true
        }, 100);
      } else if (data === 'custom') {
        this.showCustomDatepicker = true
      }
    },
    expandCollaps () {
      this.isExapndAll = !this.isExapndAll
      for (let index = 0; index < this.activitiListing.length; index++) {
        this.activitiListing[index].seeFullDesc = !this.activitiListing[index].seeFullDesc
      }
    },
    toggleActiveAndInactiveA (index) {
      let filter = []
      if (this.entStatusList[index].entityName.toLowerCase() === 'all') {
        this.entStatusList[0].selected = true
        this.filterObject.entStatusList = []
        for (let index = 0; index < this.entStatusList.length; index++) {
          this.entStatusList[index].selected = true
        }
      } else {
        this.entStatusList[index].selected = !this.entStatusList[index].selected
        this.filterObject.entStatusList = []
        this.entStatusList.forEach((a, id) => {
          if (this.entStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.entStatusList.push(a.entityId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.entStatusList.push(a.entityId)
            }
          }
        })
        this.entStatusList[0].selected = false
      }
      if (filter.length === this.entStatusList.length - 1) {
        this.entStatusList[0].selected = true
        this.filterObject.entStatusList = []
        for (let index = 0; index < this.entStatusList.length; index++) {
          this.entStatusList[index].selected = true
        }
      }
      let arry = this.entStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.entStatusList.length) {
        this.entStatusList[0].selected = true
        this.filterObject.entStatusList = []
        for (let index = 0; index < this.entStatusList.length; index++) {
          this.entStatusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      this.getActivityList()
    },
    getIcons (data) {
    if (data === 'payment') {
        return `<span class="text-success heading-4"><i class="fa-solid fa-dollar"></i></span>`
      } else if (data === 'invoice') {
        return `<span class="text-error heading-4"><i class="fas fa-file-invoice"></i></span>`
      } else if (data === 'shift') {
        return `<span class="text-success heading-4"><i class="fas fa-clock"></i></span>`
      } else if (data === 'quotation') {
        return `<span class="text-primary heading-4"> <i class="fab fa-quora"></i></span>`
      } else if (data === 'visit') {
        return `<span class="text-primary heading-4"><i class="fas fa-calendar-alt"></i></span>`
      } else if (data === 'job') {
        return `<span class="text-primary heading-4"><i class="fas fa-briefcase"></i></span>`
      } else if (data === 'customer') {
        return `<span class="text-indigo-500 heading-4"><i class="fas fa-users"></i></span>`
      } else if (data === 'lead') {
        return `<span class="text-secondary heading-4"><i class="fas fa-comment-dollar"></i></span>`
      } else if (data === 'employee') {
        return `<span class="text-primary heading-4"><i class="fas fa-user-tie"></i></span>`
      } else if (data === 'payment_method') {
        return `<span class="text-primary heading-4"><i class="far fa-money-bill-alt"></i></span>`
      } else if (data === 'tax') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-percent"></i></span>`
      } else if (data === 'item') {
        return `<span class="text-blue1 heading-4"><i class="fa-solid fa-box"></i></span>`
      } else if (data === 'expense') {
        return `<span class="text-blue1 heading-4"><i class="fa-solid fa-money-bill-transfer"></i></span>`
      } else if (data === 'request') {
        return `<span class="text-success heading-4"><i class="fa-solid fa-code-pull-request"></i></span>`
      } else if (data === 'task') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-clipboard-list"></i></span>`
      } else if (data === 'check_list_item') {
        return `<span class="text-gray-600 heading-4"><i class="fa-solid fa-list-check"></i></span>`
      } else if (data === '') {
        return `<span class="text-gray4 heading-4"><i class="fa-regular fa-circle-dot"></i></span>`
      }
    },
    openDetail (index) {
      this.activitiListing[index].seeFullDesc = !this.activitiListing[index].seeFullDesc
    },
    redirectDetail (data) {
      let url = ''
      url = this.$router.resolve({name: 'OrganizationDetail', params: {orgId: data.organizationId}})
      window.open(url.href, '_blank')
    },
    addNewCust () {
      this.addLead= true
    },
    getQuoteDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getActivityList(true)
    },
    searchCall (data) {
      console.log('data', data)
      this.searchForQuotation = data
      if (data === '') {
        this.getActivityList()
      } else {
        this.getQuotationDebounce()
      }
    },
    callsearchCust(text) {
      this.searchValCode = text
      this.getOrgListing(0)
    },
    selectCatValue(data) {
      if (data !== null) {
        this.isShowUser = true
        this.accountingCodeName = data.fullName
        this.filterObject.organizationId = data.organizationDetailId
        this.getSpecUserListing(data.organizationDetailId)
      } else {
        this.isShowUser = false
        this.accountingCodeName = ''
        this.filterObject.organizationId = 0
        this.UserName = ''
        this.filterObject.userId = ''
        this.searchValCode = ''
        this.orgID = 0
      }
      this.getActivityList()
    },
    selectUserValue (data) {
      if (data !== null) {
        console.log('selectUserValue', data)
        this.UserName = data.fullName
        this.filterObject.userId = data.userDetailId
      } else {
        this.UserName = ''
        this.filterObject.userId = ''
      }
      this.getActivityList()
    },
    getSpecUserListing (value) {
      ADMINAPI.GetSpecificUserOrg(
        value,
        response => {
          let Temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < Temp.length; index++) {
            Temp[index].fullName = Temp[index].firstName + ' ' + Temp[index].lastName
          }
          this.userList = Temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getOrgListing (orgID) {
      ADMINAPI.GetActivityOrgSearch(
        0,
        50,
        this.searchValCode,
        orgID,
        response => {
          let Temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < Temp.length; index++) {
            Temp[index].fullName = Temp[index].orgName + ' (' + Temp[index].orgCode + ')'
          }
          if (orgID > 0 && Temp.length === 1) {
            this.isShowUser = true
            this.accountingCodeName = Temp[0].fullName
            this.filterObject.organizationId = Temp[0].organizationDetailId
            this.getSpecUserListing(Temp[0].organizationDetailId)
          }
          this.accountingCodeList = Temp
          this.resetPaginationValues = false
          this.getActivityList()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getEnitityList () {
      ADMINAPI.GetActivityEntityList(
        response => {
          let temp = response.Data !== null ? response.Data : [] 
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
          }
          const newFirstElement = {
            entityId: 0,
            entityName: "All",
            entitySlug: "all",
            selected: true,
          }
          this.entStatusList = [newFirstElement].concat(temp)
          console.log('this.entStatusList', this.entStatusList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getActivityList () {
      // if (showlistLoader && this.searchForQuotation !== '') {
      //   this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      // }
      // if (this.searchForQuotation === '') {
      //   this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      // }
      // this.filterObject.entStatusList = []
      // this.entStatusList.map((data) => {
      //   if (data.selected) {
      //     this.filterObject.entStatusList.push(data.entityId)
      //   }
      // })
      this.isExapndAll = true
      ADMINAPI.GetAllOrgActivityList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForQuotation,
        this.filterObject.entStatusList,
        this.filterObject.userId,
        this.filterObject.organizationId,
        this.filterObject.from,
        this.filterObject.to,
        response => {
          this.totalQuoteCount = response.Data.count
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          //   if (temp[index].entitySlug === 'payment') {
          //     temp[index].icon = 'fa-solid fa-dollar'
          //     temp[index].color = '#1AB426'
          //   } else if (temp[index].entitySlug === 'invoice') {
          //     temp[index].icon = 'fas fa-file-invoice'
          //     temp[index].color = '#DC2626'
          //   } else if (temp[index].entitySlug === 'shift') {
          //     temp[index].icon = 'fas fa-clock'
          //     temp[index].color = '#1AB426'
          //   } else if (temp[index].entitySlug === 'quotation') {
          //     temp[index].icon = 'fab fa-quora'
          //     temp[index].color = '#1295BA'
          //   } else if (temp[index].entitySlug === 'visit') {
          //     temp[index].icon = 'far fa-clipboard'
          //     temp[index].color = '#1295BA'
          //   }
          // }
          this.activitiListing = temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
    if (window.innerWidth < 800) {
      this.mobileView = true
    } else {
      this.mobileView = false
    }
  },
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex !important;
}
</style>  