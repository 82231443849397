<template>
  <button
    @click.stop.prevent="buttonHandler"
    :class="`${isActive ? 'bg-info border-info' : 'bg-white border-gray2'}
    ${btnSize === 'medium' ? 'py-2 px-4' : ''}
    ${btnSize === 'large' ? 'py-2.5 px-4' : ''}
    ${btnSize === 'small' ? 'py-1.5 px-3' : ''}
    `"
    class=" m-1
      text-sm
      border
      hover:text-primary
      text-text1
      rounded-lg
      filter_list_btn
    "
  >
    {{ btnText }}
  </button>
</template>
<script>
export default {
  props: ["btnText", "selected", "lastInlist", "size"],
  data() {
    return {
      isActive: false,
      btnSize: 'medium',
      screenWidth: 0,
    };
  },
  watch: {
    selected: {
      handler() {
        if (this.selected) {
          this.isActive = this.selected;
        } else {
          this.isActive = this.selected;
        }
      },
    },
  },
   created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    if (window.innerWidth < 640) {
        this.btnSize = 'small'
      }
    if (this.selected) {
      this.isActive = this.selected;
    }
    if (this.size === 'medium') {
      this.btnSize = 'medium'
    }
    if (this.size === 'small') {
      this.btnSize = 'small'
    }
    if (this.size === 'large') {
      this.btnSize = 'large'
    }
  },
  methods: {
    resizeWindowHandler() {
      this.screenWidth = window.innerWidth
      if (window.innerWidth < 640) {
        this.btnSize = 'small'
      }
    },
    buttonHandler() {
      this.$emit("buttonAction");
    },
  },
};
</script>
<style scoped>
.filter_list_btn {
  min-width: 77px !important;
}
</style>

